<!-- <div style="margin: 0 auto; text-align: center; padding-top: 20px;" *ngIf="isMobile">
    <h1 *ngIf="androidLoginTitle">{{androidLoginTitle }}</h1>
    <h2 *ngIf="androidLoginError">{{androidLoginError}}</h2>
    <a *ngIf="androidLoginError" style="margin: 0 auto;" class="minimalize-styl-2 btn btn-primary"
        (click)="backClickedE()" matTooltip="חזור אחורה">
        <i style="padding: 3px;" class="fa fa-arrow-right"></i>
    </a>
</div> -->
<div style="margin: 0 auto; width: fit-content;"  fxFlex="1 1 auto" fxFlexFill>

    <div *ngIf="!isMobile || showNormal" fxFlex="1 1 auto" fxFlexFill
        style="margin: 10px auto; padding: 20px; background-color: white" class="text-center">
        <h1 style="direction: ltr; font-size: 50px; font-weight: 500">מתן בסתר</h1>
        <h1>ברוכים הבאים</h1>
        <p>הזן את פרטיך כדי להתחבר.</p>

        <div class="card-body">
            <form class="form-horizontal" style="white-space: nowrap;" #f="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-group" style="margin-bottom: 10px;">
                    <label class="control-label col-sm-3" style="float: right;" for="username">שם משתמש:</label>
                    <div class="col-sm-9">
                        <span class="example-fill-remaining-space"></span>
                        <input style="float: left;" type="text" class="form-control" name="userName" id="userName"
                            [(ngModel)]="loginModel.userName" required />
                    </div>
                </div>
                <div class="form-group" style="margin-bottom: 10px;">
                    <label class="control-label col-sm-3" style="float: right;" for="username">סיסמה:</label>
                    <div class="col-sm-9">
                        <input style="float: left;" type="password" class="form-control" name="password" id="password"
                            [(ngModel)]="loginModel.password" required />
                    </div>
                </div>
                <div class="form-group" style="margin-bottom: 10px;">
                    <button [disabled]="loading" type="submit" class="btn btn-primary"
                        style="width: 80%; font-weight: bold;">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        התחבר
                    </button>
                </div>
            </form>
        </div>
        <h3 style="color: red;" *ngIf="errorLoginMsg">{{errorLoginMsg}}</h3>
        <p class="m-t"> <small>כל הזכויות שמורות. &copy; 2023</small> </p>
    </div>
</div>
