import { DashboardTrackingAccountsCalendar } from './dashboard-tracking-accounts/dashboard-tracking-accounts-calendar.component'; 
import { AuthGuard } from './../_guards/auth.guard';
import { CommitmentsListDashboardDlg } from '../commitments/commitments-list-dashboard-dlg.cmp';
import { DashboardLocatorComponent } from './dashboard-locator/dashboard-locator.cmp';
import { Routes } from '@angular/router';
import { DashboardDirectorChartsComponent } from './dashboard-director/dashboard-director-charts.cmp';
import { DashboardSecretaryComponent } from './dashboard-secretary/dashboard-secretary.cmp';
import { DashboardGroupsComponent } from './‏‏dashboard-groups/dashboard-groups.cmp';
import { DashboardChartsMainComponent } from './dashboard-charts/dashboard-charts-main.component';
import { ScreenComponent } from '../screen/screen.component';
import { DashboardGroupsSpecialComponent } from './dashboard-groups-special/dashboard-groups-special.cmp'; 
import { DashboardMarbeMainComponent } from './dashboard-marbe/dashboard-marbe-main.component'; 

export const DashboardRouting: Routes = [
  {
    canActivate: [AuthGuard], path: 'dashboard', data: { roles: "dashboard", title: 'לוח בקרה' },
    children: [
      { canActivate: [AuthGuard], path: '', pathMatch: 'full', component: DashboardLocatorComponent, data: { title: 'לוח בקרה' } },
      // { canActivate: [AuthGuard], path: 'lottery', component: DashboardLotteryMainComponent, data: { title: 'עת לחזק' } },
      { canActivate: [AuthGuard], path: 'marbe', component: DashboardMarbeMainComponent, data: { title: 'מרבה תורה' } },
      { canActivate: [AuthGuard], path: 'charts', component: DashboardChartsMainComponent, data: { title: 'עדכונים שוטפים' } },
      { canActivate: [AuthGuard], path: 'groups', component: DashboardGroupsComponent, data: { title: 'קבוצות' } },
      { canActivate: [AuthGuard], path: 'groupsSpecials', component: DashboardGroupsSpecialComponent, data: { title: '' } },
      { canActivate: [AuthGuard], path: 'dashboardDirector', component: DashboardDirectorChartsComponent, data: { title: 'מצב קופה' } },
      { canActivate: [AuthGuard], path: 'secretary', component: DashboardSecretaryComponent, data: { roles: "dashboardSecretary", title: 'כללי' } },
      { canActivate: [AuthGuard], path: 'commitments', component: CommitmentsListDashboardDlg, data: { title: 'התחייבויות' } },
      { canActivate: [AuthGuard], path: 'trackingAcconts', component: DashboardTrackingAccountsCalendar, data: { title: 'מעקב תשלומים' } },
      { canActivate: [AuthGuard], path: 'screenIndex', component: ScreenComponent, data: { title: 'את פסח לו' } },
      { canActivate: [AuthGuard], path: 'directorReports', loadChildren: () => import(`./dashboard-director-reports/dashboard-director-reports.mdl`).then(m => m.DashboardDirectorReportsModule) },


    ]
  }
];
